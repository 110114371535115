import { useStorage } from '@/hooks/storage.hooks';
import { createI18n, useI18n } from 'vue-i18n';

import messages from './lang';
import type { LocaleKey } from './lang';
import type locale from './lang/de';

export const defaultLocale: LocaleKey = 'de';

export const i18n = createI18n<[typeof locale], LocaleKey>({
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages,
    warnHtmlInMessage: 'off',
    legacy: false,
});

const localeStorageKey = 'lang';

export type LocaleOption = {
    value: LocaleKey;
    label: string;
    icon: string;
};

export const localeOptions: LocaleOption[] = [
    {
        value: 'de',
        label: 'Deutsch',
        icon: '🇩🇪',
    },
    {
        value: 'en',
        label: 'English',
        icon: '🇬🇧',
    },
];

export function translate(key: string) {
    const { t } = useI18n();
    return t(key);
}

export function setLocale(locale: LocaleKey) {
    const [, setValue] = useStorage<LocaleKey>(localeStorageKey);
    setValue(locale);
    (i18n.global.locale as unknown as Ref<string>).value = locale;
}

export function getLocale(): LocaleKey {
    const [localeValue] = useStorage<LocaleKey>(localeStorageKey);
    return localeValue.value ? localeValue.value : (i18n.global.locale as LocaleKey);
}
