import { authGuard, useAuth0 } from '@auth0/auth0-vue';
import { watch } from 'vue';
import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

const redirectToTenantPortalIfAuthenticated = async () => {
    const { isAuthenticated, isLoading } = useAuth0();

    let resolve: (value: boolean) => void;
    const promise = new Promise<boolean>(_resolve => {
        resolve = _resolve;
    });

    watch(isLoading, val => {
        if (!val) {
            resolve(isAuthenticated.value);
        }
    });

    const authenticated = await promise;

    if (authenticated) {
        return { name: 'TenantPortalHome' };
    }
};

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('@/pages/Welcome.vue'),
        name: 'Welcome',
        beforeEnter: [redirectToTenantPortalIfAuthenticated],
    },
    {
        path: '/',
        component: () => import('@/layout/LayoutPublic.vue'),
        children: [
            {
                path: 'landing',
                component: () => import('@/pages/Landing.vue'),
                name: 'landing',
            },
            {
                path: 'impressum',
                component: () => import('@/pages/Impressum.vue'),
                name: 'impressum',
            },
            {
                path: 'datenschutz',
                component: () => import('@/pages/Datenschutz.vue'),
                name: 'datenschutz',
            },
            {
                path: 'apartments',
                component: () => import('@/pages/Apartments.vue'),
                name: 'apartments',
            },
            {
                path: 'apartment/:id',
                component: () => import('@/pages/Apartment.vue'),
                name: 'apartment',
            },
            {
                path: '/:pathMatch(.*)*',
                component: () => import('@/pages/404.vue'),
                name: '404',
            },
            {
                path: 'library',
                component: () => import('@/pages/Library.vue'),
                name: 'library',
            },
        ],
    },
    {
        path: '/tenant-portal',
        component: () => import('@/layout/TenantPortalLayout.vue'),
        name: 'TenantPortal',
        props: route => ({
            showNavigation: route.name !== 'TenantPortalHome',
        }),
        children: [
            {
                path: 'home',
                component: () => import('@/pages/tenant-portal/TenantPortalHome.vue'),
                name: 'TenantPortalHome',
            },
            {
                path: 'tenancies/:tenancyId',
                component: () => import('@/layout/TenantPortalNavigationLayout.vue'),
                name: 'TenantPortalNavigationLayout',
                children: [
                    {
                        path: 'rent-info',
                        component: () => import('@/pages/tenant-portal/TenantPortalRentInfo.vue'),
                        name: 'TenantPortalRentInfo',
                    },
                    {
                        path: 'support',
                        component: () => import('@/pages/tenant-portal/TenantPortalSupport.vue'),
                        name: 'TenantPortalSupport',
                    },
                    {
                        path: 'documents',
                        component: () => import('@/pages/tenant-portal/TenantPortalDocuments.vue'),
                        name: 'TenantPortalDocuments',
                    },
                    {
                        path: 'faq',
                        component: () => import('@/pages/tenant-portal/TenantPortalFaq.vue'),
                        name: 'TenantPortalFaq',
                    },
                    {
                        path: 'personal-info',
                        component: () => import('@/pages/tenant-portal/TenantPortalPersonalInfo.vue'),
                        name: 'TenantPortalPersonalInfo',
                    },
                ],
            },
        ],
        beforeEnter: [authGuard],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            };
        }

        return { top: 0 };
    },
});

export default router;
