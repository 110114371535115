import type { ICondoLivingClientConfig } from '@/types';
import { defineStore } from 'pinia';

import type { ITenancyInformation } from '@condo/domain';

export const useConfigStore = defineStore('config', () => {
    const config = ref<ICondoLivingClientConfig | null>(null);

    async function setConfig(configObj: ICondoLivingClientConfig) {
        config.value = configObj;
    }

    return { config: computed(() => config.value), setConfig };
});

export const useSelectedTenancy = defineStore('tenancy', () => {
    const tenancy = ref<ITenancyInformation | null>(null);

    function setSelectedTenancy(value: ITenancyInformation) {
        tenancy.value = value;
    }

    return { tenancy: computed(() => tenancy.value), setSelectedTenancy };
});
