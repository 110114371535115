import { useConfigStore } from '@/store/store';
import type { ICondoLivingClientConfig } from '@/types';

export const useEnv = (config?: ICondoLivingClientConfig) => {
    if (config) {
        return config.ENVIRONMENT_NAME || 'local';
    }

    const configStore = useConfigStore();

    return configStore?.config?.ENVIRONMENT_NAME || 'local';
};
