import { auth0Client, getBearerToken } from '@/plugins/auth0-client';
import axios from 'axios';
import type { AxiosInstance } from 'axios';
import qs from 'qs';
import { v4 as uuid } from 'uuid';
import { useToast } from 'vue-toastification';
import { TYPE } from 'vue-toastification/src/ts/constants';

import RequestError from '../components/toasts/RequestError.vue';

const toast = useToast();

export let api: AxiosInstance = null as any;

export const handleRequestError = async (error: any) => {
    switch (error?.response?.data?.statusCode) {
        case 401:
            // @todo: ensure logged out first by trying to force it first, to avoid infinite redirect loops
            await auth0Client.loginWithRedirect({
                authorizationParams: {
                    redirect_uri: `${window.location.origin}/tenant-portal`,
                    scope: 'openid profile email',
                },
                appState: { target: '/tenant-portal' },
            });
            break;
        default:
            toast(RequestError, { timeout: 15000, type: TYPE.ERROR });
    }
};

export const extendAxiosClient = (baseURL: string): AxiosInstance => {
    const client = axios.create({
        baseURL,
        paramsSerializer: {
            serialize: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        },
    });

    client.interceptors.request.use(async config => {
        config.headers['x-stream-id'] = uuid();

        try {
            const token = await getBearerToken();

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        } catch (_) {
            return config;
        }
    });

    client.interceptors.response.use(
        response => response,
        async error => {
            await handleRequestError(error);
            return Promise.reject(error);
        },
    );

    return client;
};

export const initCondoLivingApi = (baseURL: string) => {
    api = extendAxiosClient(baseURL);
};
