import de from './de';
import en from './en';

const locales = {
    de,
    en,
};

export type LocaleKey = keyof typeof locales;

export default locales;
