import { type Ref, ref } from 'vue';

const getItem = (key: string, storage: Storage) => {
    const value = storage.getItem(key);
    if (!value) {
        return null;
    }
    try {
        return JSON.parse(value);
    } catch (error) {
        return value;
    }
};

export const useStorage = <T>(key: string): [Ref<T | null>, (newValue: T) => void, () => void] => {
    const storage = localStorage;
    const value: Ref<T | null> = ref(getItem(key, storage));

    const setItem = (newValue: T) => {
        storage.setItem(key, JSON.stringify(newValue));
        value.value = newValue;
    };

    const clear = () => {
        storage.removeItem(key);
        value.value = null;
    };

    return [value, setItem, clear];
};
